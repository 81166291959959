import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'





const FaqSection = () => {

  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">
      
      <Container>
          <h5 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h5>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'} >
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is a cryptocurrency exchange script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Cryptocurrency exchange script is a multi-tested, ready-made white label software that allows users to trade Bitcoin in a hassle-free manner. By using the ready-made script, startups can develop an exchange and customize features, front-end designs, themes.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'} >
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How much does it cost to build a Cryptocurrency Trading Platform? 
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The development cost mainly depends on the features that you enable and the type of exchange you want to create for your business. Apart from these, the development cost will vary as per your unique business requirements.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'} >
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How Long Does it Take to create my own cryptocurrency exchange?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">If you are planning to build your own trading platform quickly, then you can use premium ready to use software. So that you can launch your own cryptocurrency exchange in just 7 days
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'} >
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                Where can I get the best Cryptocurrency Exchange Script?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">You can get the first-class cryptocurrency exchange script from Coinsclone at a budget-friendly price. We offer peculiar trading features and an eye-catching UI/UX to ensure the best cryptocurrency trading experience for your users.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card mb-0'} >
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How can I make money by Starting a Cryptocurrency trading platform?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">You can generate a lucrative amount of money in multiple ways by building a cryptocurrency trading platform. Such as withdrawal fees, trading commission, deposit fees, margin trading, advertisement, stacking, and integrating IEO launchpad into your platform that helps token issuers to launch their cryptocurrency/tokens on your platform. For doing this, you collect fees from them.
                </div>
              </Accordion.Collapse>
            </div>

        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection