import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'




class WhyCoins extends React.Component {

  render() {


    return (
      <section className="whycoins pt-100 mb-0">
        <div className="container">
              <h4 className="heading-h2 text-center"><span className="heading-h3 bluecolor">Why Cryptocurrency exchange</span>
                Script from Coinsclone?</h4>
          <div className="row table-content frst orderflex">
            <div className="col-lg-6 col-md-12 order2">
              <p className="pharagraph">Coinsclone is the predominant and outstanding <span className='learn-more'><a href='https://www.coinsclone.com/cryptocurrency-exchange-development-company/'>cryptocurrency exchange development company</a></span> in the crypto sector. We have years of rock-solid experience in crafting and delivering premium software with salient features at the best price in the market. Being a pioneer in the crypto space, we are masters in affording all types of cryptocurrency exchange scripts with best-in-class outcomes. So far we have delivered 350+ projects successfully to our reputed international customers across the globe.
              </p>
              <p className="pharagraph mb-0">We can say with pride that we are offering first-quality cryptocurrency exchange script with excellent features that can be completely customizable based on your business concepts. Our top-notch clone script helped many global clients to create their dream cryptocurrency trading platform. We have a team of skilled and well-experienced blockchain developers to craft the software with all essential trading features.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 text-center mmb20" >
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/why-choose-crypto-coinsclone.png" 
              alt="Crypto Exchange Clone Script From Coinsclone image1"
              width={575} />
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center mmb20" >
            <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/why-choose-coinsclone-2.png" 
              alt="Crypto Exchange Clone Script From Coinsclone image1"
              width={575} />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Besides, we have a dedicated team for providing effective technical and customer support on a 24*7 basis. Some notable reasons to pick up Coinsclone are given below.</p>
              <ul className="mb-0">
                <li className="pharagraph" >We have 7+ years of experience in developing error-free Blockchain solutions
                </li>
                <li className="pharagraph" >Delivered 350+ Blockchain projects
                </li>
                <li className="pharagraph" >We have a handful of experienced developers and a dedicated team of experts for customer support
                </li>
                <li className="pharagraph" >We use advanced technology stacks for crafting the software
                </li>
                <li className="pharagraph mb-0" >24/7 Technical support through the internet
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyCoins