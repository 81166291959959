import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image'



const HowOur = () => {



  return (
    <div className='pt-100'>
    <section className="how-our mb-0">
      <div className="container">
          <h2 className="heading-h2 text-center"> <span className="heading-h3 bluecolor"> How Does </span>Our Cryptocurrency Exchange Script work?</h2>
        <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/workflow-coinsclone.webp" 
              alt="coinsclone-workflow"
              width={1100} />
      <div className="text-center mt-25">
          <ButtonComponent/>
        </div>
      </div>
    </section>
    </div>
  )
}

export default HowOur