import React from "react";
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Landing/Banner"
import Whatis from "../components/Landing/Whatis"
import QuickviewSection from "../components/Landing/Quickview"
import VariousTypes from "../components/Landing/VariousTypes"
import TopFeatures from "../components/Landing/TopFeatures"
import OurAndroid from "../components/Landing/OurAndroid"
import CoreFeatures from "../components/Landing/CoreFeatures"
import SecurityofOur from "../components/Landing/SecurityofOur"
import PortfolioSection from "../components/Landing/Portfolio"
import Benefits from "../components/Landing/Benefits"
import WhyCoins from "../components/Landing/WhyCoins"
import Testimonial from "../components/Landing/Testimonial"
import TechStack from "../components/Landing/TechStack"
import FaqSection from "../components/Landing/FaqSection"
import HowOur from "../components/Landing/HowOur";
import UserFriendly from "../components/Cryptoexchange/UserFriendly";
import ReviewNew from "../components/Landing/Review-new";





const Index = () => (

  <section className="landing">
    <Helmet>
      <meta name="ahrefs-site-verification" content="1750638eb8c1a510a359f1ec03f28cf744c4a3df7b930b9555da4cfd4dbded1b" />
      <meta name="robots" content="index, follow" />
      <title>Cryptocurrency Exchange Script to Start a Bitcoin Exchange</title>
      <meta name="description" content="Cryptocurrency exchange script is a pre-fabricated exchange software that helps you to create and launch a user-friendly trading platform instantly" />
      <meta name="keywords" content="Cryptocurrency exchange script" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cryptocurrency Exchange Script to Start a Bitcoin Exchange" />
      <meta property="og:description" content="Cryptocurrency exchange script is a pre-fabricated exchange software that helps you to create and launch a user-friendly trading platform instantly" />
      <meta property="og:url" content="https://www.coinsclone.com/" />
      <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/cryptocurrency-exchange-clone-script.png" />
      <meta property="og:site_name" content="Coinsclone" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Coinsclone" />
      <meta name="twitter:creator" content="@Coinsclone" />
      <meta name="twitter:description" content="Cryptocurrency exchange script is a pre-fabricated exchange software that helps you to create and launch a user-friendly trading platform instantly" />
      <meta name="twitter:title" content="Cryptocurrency Exchange Script to Start a Bitcoin Exchange" />
      <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/cryptocurrency-exchange-clone-script.png" />
      <meta name="msvalidate.01" content="4C6D3C657B662E541A5B7866529A7123" />
      <link rel="canonical" href="https://www.coinsclone.com/" />
      <link rel="preload" fetchpriority="high" href="https://coinsclone.mo.cloudinary.net/images/home/crypto-exchange-development-script.png" type="image/webp" as="image"/>
    </Helmet>
    <Layout>
          <Banner />
          <Whatis />
          <QuickviewSection />
          <HowOur />
          <VariousTypes />
          <TopFeatures />
          <OurAndroid />
          <CoreFeatures />
          <SecurityofOur />
          <PortfolioSection />
          <Benefits />
          <WhyCoins />
          <Testimonial />
          <ReviewNew />
          <TechStack />
          <FaqSection />
          <UserFriendly />
    </Layout>
  </section>
)

export default Index
