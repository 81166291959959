import React from "react"

class Whatis extends React.Component {
  render() {
    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="gray-bg">
          <div className="container">
                  <h2 className="heading-h2 text-center">
                    <span className="heading-h3 bluecolor">What is </span>
                    Cryptocurrency Exchange Script?
                  </h2>
              
              <p className="text-center pharagraph mb-0">
                  Cryptocurrency exchange script is a pre-constructed Bitcoin
                  exchange software which replicates the functionalities of a
                  popular Crypto trading platform. Our script comes with the
                  latest trading modules and robust security features.
                  Coinsclone’s premium software is fully customizable, so you
                  can modify themes, features, designs, and other add-ons as per
                  the business requirements in a hassle-free manner. We craft our bug-free script with the new-fashioned
                  technologies and cutting-edge tools. Our clone script is
                  highly responsive, secure, and runs smoothly without any
                  glitches.
              </p>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis
